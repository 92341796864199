<template>
  <div class="about">
    <h1>SMILE連携</h1>
    <v-form v-model="valid" ref="form" @submit.prevent="submit">
      <v-autocomplete
        v-model="selectFileType"
        :items="fileTypeList"
        label="取込対象"
        prepend-icon="mdi-account-cog"
        :rules="[Rules.Required]"
        outlined
        item-text="name"
        item-value="code"
      />
      <v-file-input
        v-model="inputFile"
        label="取込ファイル"
        prepend-icon="mdi-file-import"
        outlined
        :rules="[Rules.Required, fileRule]"
        @input="inputFileReset"
      />
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";

import Forms from "@/mixins/forms";
import Api from "@/mixins/api";

export default {
  name: "SmileLinkage",
  mixins: [Common, Forms, ShowDialogs, Api],
  components: {},
  data: () => ({
    inputFile: null,
    selectFileType: "",
    fileTypeList: [],
    fileRule: value =>
      !value || value.type === "text/csv" || "CSVファイルを選択してください。"
  }),
  computed: {},
  methods: {
    inputFileReset() {
      console.log(this.inputFile);
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("csv:import", "start");

      const confirm = await this.$confirm(
        "SMILEのデータを取り込みますか？",
        "SMILE連携"
      );
      if (!confirm) return;
      this.$loading();

      try {
        let csvArr = [[]];
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result;
          const lines = result.split("\n");
          csvArr = lines.map(e => e.split(","));
          console.log(csvArr);
          return;
        };
        reader.readAsText(this.inputFile, "UTF-16");

        //if(csvArr.length === 0) this.$error("CSVファイル内にデータが存在しません");
        //if(csvArr.length === 1) this.$error("CSVファイル内にデータが存在しません");

        const postType = this.selectFileType;
        switch (postType) {
          case "1":
            //if(csvArr[0][0] !== "社員ｺｰﾄﾞ") this.$error("CSVファイル内のヘッダーが");
            await this.$csvupload(this.Paths.smileLinkageShain, this.inputFile);
            break;
          case "2":
            //if(csvArr[0][0] !== "所属ｺｰﾄﾞ") this.$error("CSVファイル内のヘッダーが正しくありません。\n");
            await this.$csvupload(
              this.Paths.smileLinkageShozoku,
              this.inputFile
            );
            break;
          case "3":
            //if(csvArr[0][0] !== "社員ｺｰﾄﾞ") this.$error("CSVファイル内のヘッダーが正しくありません。\n");
            await this.$csvupload(this.Paths.smileLinkageTanka, this.inputFile);
            break;
          default:
        }
        this.$info("データの取り込みが終了しました", "SMILE連携");
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
        console.log("csv:import", "end");
      }
    },
    //GetAPI
    async getFileTypeList() {
      console.log("getFileTypeList");
      const params = { kbn: "FILEIM" };
      return await this.$get(
        this.Paths.codeMasterPulldown,
        "query=" + encodeURI(JSON.stringify(params))
      );
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log("created", this.date);
    this.$loading();

    try {
      this.fileTypeList = await this.getFileTypeList();
    } finally {
      this.$unloading();
    }
  }
};
</script>
